import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/reisediarrhoe-behandlung"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627 active-trail">
                      <a
                        href="/reisedurchfall-vorbeugen/"
                        className="active-trail"
                      >
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-auf-reisen"
                  className="block block-menu"
                >
                  <h2>Durchfall auf Reisen</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/reisedurchfall-vorbeugen/" title>
                          Vorbeugung von Reisedurchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/gesundheitsratschlage-fur-die-reise/" title>
                          Reiseapotheke
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/kritische-reiseziele/" title>
                          Kritische Reiseziele
                        </a>
                      </li>
                      <li className="last leaf active-trail">
                        <a
                          href="/reisediarrhoe-behandlung/"
                          title
                          className="active-trail active"
                        >
                          Behandlung von Reisedurchfall
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-15" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        lineHeight: "26px",
                        margin: "20px 0 10px 0",
                        fontFamily: "'Open Sans', sans-serif",
                      }}>
                        IMODIUM®
                        <br />
                        lingual
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/Produkt_Teaser_Unterseite_lingual.jpg"
                          style={{
                            width: "174px",
                            height: "107px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Perfekt für unterwegs! Schmilzt in Sekunden auf der
                        Zunge und kann ohne Wasser eingenommen werden.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/imodium-lingual/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Behandlung von Reisedurchfall
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/header_visuals_695x286_reise.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Behandlung
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                          Im Folgenden finden Sie Informationen für den Fall, dass während des Urlaubs Durchfall auftritt.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <div>
                                <h2>Durchfall-Symptome:</h2>
                              </div>
                              <ul>
                                <li>Flüssiger Stuhl</li>
                                <li>
                                  Mehrmals täglich auftretende Stuhlentleerungen
                                </li>
                                <li>Häufig assoziiert mit Übelkeit</li>
                                <li>
                                  Erbrechen und krampfartigen Bauchbeschwerden
                                </li>
                                <li>Je nach Ursache auch Fieber</li>
                              </ul>
                              <p>
                              Bei akutem Durchfall kann der Körper mit den 
                              folgenden Mitteln und der richtigen 
                              Ernährung bei der Genesung unterstützt
                              werden.
                              </p>
                              <h2>Ursachen für Durchfall:</h2>
                              <p>
                               Durchfall kann viele Ursachen haben.
                               Mögliche Ursachen sind
                               Magen-Darm-Infektionen, ausgelöst
                               durch Viren (z.B. Rotaviren),
                               Bakterien (z.B. Kolibakterien) oder
                               Parasiten (z.B. Amöben, Giardien / Lamblien oder Kryptosporidien).
                              </p>
                              <ul>
                                <li>Unreines Trinkwasser</li>
                                <li>
                                  Ungewohnte Klimaverhältnisse und Lebensmittel
                                </li>
                                <li>Parasiten</li>
                                <li>Geschwächtes Immunsystem</li>
                              </ul>
                              <h2>Dauer und Verlauf von Durchfall:</h2>
                              <p>
                                In den meisten Fällen dauern die Durchfälle etwa
                                drei bis vier Tage an. Je nachdem, welche Ursache dem
                                Reisedurchfall zugrunde liegt, kann sich in seltenen Fällen
                                eine ernstere Durchfallerkrankung (z.B.
                                bakterielle Ruhr, Amöbenruhr, Cholera)
                                entwickeln. Deshalb ist es wichtig, dass Sie
                                Ihre Beschwerden dokumentieren und ernst nehmen.
                                Besonders auf Reisen stellt sich die Frage, ab
                                wann es notwendig ist, einen Arzt zu
                                konsultieren. In jedem Fall sollte ein Arzt
                                aufgesucht werden, wenn:
                              </p>
                              <ul>
                                <li>
                                  der Durchfall länger als 48 Stunden anhält,
                                </li>
                                <li>der Stuhlgang blutig ist,</li>
                                <li>
                                  der Durchfall durch Fieber (über 38 Grad
                                  Celsius) begleitet wird.
                                </li>
                              </ul>
                              <p>
                                Falls Sie während einem längeren Urlaub
                                wiederholt unter akutem Durchfall leiden, ist es
                                empfehlenswert ein Tagebuch zu führen. So
                                besteht die Chance, den Auslöser zu
                                identifizieren und zu eliminieren, damit Sie den
                                Rest Ihres Urlaubs geniessen können.
                              </p>
                              <h2>Durchfall nach der Reise:</h2>
                              <p>
                                Wenn Sie nach der Rückkehr von Ihrer Reise
                                Durchfall bekommen, suchen Sie einen Arzt auf,
                                insbesondere wenn Sie in einem tropischen Land
                                mit erhöhten Reisedurchfallrisiko waren. Dieser
                                kann feststellen, ob der Durchfall von
                                Krankheiten oder Parasiten aus dem Reiseland
                                nachträglich ausgelöst wurde und gegebenenfalls
                                die passende Behandlung einleiten.
                              </p>
                              <p>
                                Falls Sie während Ihrer Reise von akutem
                                Durchfall heimgesucht wurden und sich nach Ihrer
                                Rückkehr weiterhin unwohl fühlen oder einfach
                                nur auf Nummer sichergehen wollen, suchen Sie
                                ebenfalls Ihren Arzt auf.
                              </p>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
